// export const primaryColor = '#3498db';
// export const primaryColorLighten = '#38A2E8';
// export const primaryColorDarken = '#256C9C';
// export const primaryColorDarkest = '#16405C';
//
// export const colorgreylight1 = '#f9f7f6';
// export const cologreylight2= '#aaa';
//
// export const colorgreydark1 = '#54483A';
// export const colorgreydark2 = '#6D5D4B';

export const Colors = {
  primaryColor: '#3498db',
  primaryColorLighten: '#38A2E8',
  primaryColorDarken: '#005DA8',
  primaryColorDarkest: '#16405C',
  greyColor: '#525252',

  colorGreyLight1: '#f9f7f6',
  colorGreyLight3: '#d0c8c3',
  coloGreyLight2: '#aaa',

  colorGreyDark1: '#54483A',
  colorGreyDark2: '#6D5D4B',
};
export const Fonts = {
  main: 'Oswald, sans-serif',
  secondary: `EB Garamond, serif`,
};
