import posed, {PoseGroup} from 'react-pose';
import React from 'react';
import { Container, AbsoluteCase, SVG } from './logo.css';
import { Colors } from '../../../constants/theme';

const staggerDuration = 1000;
const PathTextGroupMain = posed.g({
  empty: {
  },
  go: {
    delayChildren: 200,
    staggerChildren: 200,
  },
});

const PathTextGroupSecondary = posed.g({
  go: {
    delayChildren: 200,
    staggerChildren: 150,
  },
});

const PathTextMain = posed.path({
  empty: {
    opacity: 0,
    fill: '#003498db',
    pathLength: 0,
    y: -10,
  },
  go: {
    opacity: 1,
    fill: Colors.colorGreyLight1,
    y: 0,
    pathLength: 1,
    transition: ({ i }) => ({ delay: i * 100, duration: 400, ease: 'linear' })
  },
});

const PathTextSecondary = posed.path({
  empty: {
    opacity: 0,
    fill: '#003498db',
    pathLength: 0,
    y: -10,
  },
  go: {
    y: 0,
    opacity: 1,
    pathLength: 1,
    fill: Colors.colorGreyLight1,

    transition: ({ i }) => ({ delay: i * 80, duration: 400, ease: 'linear' })
  },
});

const PathDiagnostic = posed.path({
  empty: {
    pathLength: 0,
  },
  go: {
    pathLength: 1,
    strokeWidth: '4px',
    transition: {
      delay: 1500,
      duration: 1000,
      easing: 'ease-in',
    },
  },
});

class LogoAnimation extends React.Component {
  state = {
    active: false,
    isVisible: false,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isVisible) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  }

  render() {
    const { active } = this.state;
    const  { referrer } = this.props;
    return (
      <Container
        ref={referrer}
        padding={this.props.padding}>
        <AbsoluteCase height={'4rem'} width={'13.4rem'}>
          <SVG
            viewBox={'155 -25 100 140'}
            version={'1.1'}
            xmlns={'http://www.w3.org/2000/svg'}

            width="100%"
            height="100%"
            zIndex={'100'}
          >
            <PoseGroup
              pose={this.state.active ? 'go' : 'empty'}>
              <PathTextMain
                i={1}
                d="M 0.004 54 L 0.004 14.2 A 15.757 15.757 0 0 1 1.182 7.863 Q 4.609 0 18.004 0 Q 23.804 0 29.604 1.6 L 29.604 8.2 Q 23.704 6.5 17.804 6.5 Q 12.967 6.5 10.482 7.878 A 5.923 5.923 0 0 0 9.504 8.55 Q 7.104 10.6 7.104 14.6 L 7.104 53.7 A 9.895 9.895 0 0 0 7.38 56.112 A 6.565 6.565 0 0 0 9.554 59.65 Q 11.668 61.419 16.537 61.662 A 32.537 32.537 0 0 0 18.154 61.7 A 42.333 42.333 0 0 0 27.719 60.644 A 38.584 38.584 0 0 0 29.804 60.1 L 29.804 66.6 A 31.597 31.597 0 0 1 24.721 67.749 Q 22.198 68.115 19.341 68.184 A 55.555 55.555 0 0 1 18.004 68.2 A 33.48 33.48 0 0 1 11.434 67.614 Q 1.153 65.547 0.119 56.183 A 19.91 19.91 0 0 1 0.004 54 Z"
                key="0"
              />
              <PathTextMain
                i={2}
                d="M 40.404 67.7 L 40.404 0.5 L 69.504 0.5 L 69.504 6.8 L 47.504 6.8 L 47.504 30.7 L 66.704 30.7 L 66.704 37 L 47.504 37 L 47.504 61.4 L 69.504 61.4 L 69.504 67.7 L 40.404 67.7 Z"
                key="1"
              />
              <PathTextMain
                i={3}
                d="M 109.904 56.3 L 109.204 34.2 L 109.204 0.5 L 116.304 0.5 L 116.304 67.7 L 108.104 67.7 L 94.304 32.9 Q 93.549 30.918 87.182 14.062 A 115731.238 115731.238 0 0 1 86.404 12 L 87.004 34 L 87.004 67.7 L 80.104 67.7 L 80.104 0.5 L 88.304 0.5 A 8861.514 8861.514 0 0 1 95.407 18.602 Q 105.584 44.63 109.904 56.3 Z"
                key={"2"}
              />
              <PathTextMain
                i={4}
                d="M 124.104 0.5 L 157.304 0.5 L 157.304 6.8 L 144.304 6.8 L 144.304 67.7 L 137.104 67.7 L 137.104 6.8 L 124.104 6.8 L 124.104 0.5 Z"
                key="3"
              />
              <PathTextMain
                i={5}
                d="M 191.904 67.7 L 182.104 40.7 L 172.204 40.7 L 172.204 67.7 L 165.104 67.7 L 165.104 0.5 L 181.404 0.5 Q 186.955 0.5 190.628 2.45 A 12.209 12.209 0 0 1 193.504 4.55 A 13.431 13.431 0 0 1 197.203 11.513 A 19.801 19.801 0 0 1 197.604 15.6 L 197.604 25.5 A 20.666 20.666 0 0 1 196.997 30.701 Q 195.385 36.898 189.49 39.31 A 16.226 16.226 0 0 1 189.004 39.5 L 199.704 67.7 L 191.904 67.7 Z M 172.204 6.8 L 172.204 34.5 L 181.104 34.5 A 14.032 14.032 0 0 0 184.767 34.065 Q 188.957 32.929 190.087 28.829 A 12.925 12.925 0 0 0 190.504 25.4 L 190.504 15.9 A 12.637 12.637 0 0 0 190.021 12.244 Q 188.805 8.225 184.524 7.173 A 14.368 14.368 0 0 0 181.104 6.8 L 172.204 6.8 Z"
                key="4"
              />
              <PathTextMain
                i={6}
                d="M 237.004 52.7 L 237.004 0.5 L 244.104 0.5 L 244.104 52.9 A 19.259 19.259 0 0 1 243.457 58.021 A 14.341 14.341 0 0 1 239.854 64.35 Q 235.604 68.7 227.204 68.7 A 25.809 25.809 0 0 1 222.36 68.275 Q 219.651 67.757 217.545 66.606 A 12.607 12.607 0 0 1 214.554 64.35 Q 210.304 60 210.304 52.9 L 210.304 0.5 L 217.404 0.5 L 217.404 52.7 Q 217.404 62.3 227.204 62.3 Q 237.004 62.3 237.004 52.7 Z"
                key="5"
              />
              <PathTextMain
                i={7}
                d="M 296.204 0.5 L 304.704 0.5 L 304.704 67.7 L 297.904 67.7 L 297.904 32.3 Q 297.904 22 298.504 12.4 L 284.804 49.8 L 277.304 49.8 L 263.404 12.4 A 247.296 247.296 0 0 1 264.005 24.494 A 295.23 295.23 0 0 1 264.104 32.2 L 264.104 67.7 L 257.404 67.7 L 257.404 0.5 L 265.804 0.5 L 281.004 42.6 L 296.204 0.5 Z"
                key="7"
              />
            </PoseGroup>
          </SVG>
        </AbsoluteCase>
        <AbsoluteCase width={'12.5rem'} height={'9rem'}>
          <SVG
            version="1.1"
            id="Capa_2"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="160 0 200 300"
            stroke={Colors.primaryColorDarken}
            strokeWidth="3px"
          >
            <g transform="scale(1.1, 1.1)" fill="transparent">
              <PathDiagnostic
                pose={this.state.active ? 'go' : 'empty'}
                d="M 431.75067138671875 82.81319427490234 C 417.1039733886719 96.8456039428711 414.6074523925781 78.84711456298828 400.5963439941406 85.8526611328125 C 388.4385986328125 91.93154907226562 405.1554260253906 167.15789794921875 387.6786193847656 168.6776123046875 C 363.3630065917969 176.27618408203125 388.5975341796875 4.520626064389944 364.1229248046875 -0.011722568422555923 C 343.60662841796875 -3.811038974672556 356.5242919921875 104.08934783935547 353.48486328125 153.48037719726562 C 351.8871765136719 169.45712280273438 355.004638671875 201.3516845703125 344.3665771484375 205.91082763671875 C 332.6502990722656 204.845703125 334.4884033203125 173.23675537109375 333.7284851074219 151.96063232421875 C 332.1308288574219 111.59686279296875 334.48834228515625 97.2506103515625 318.53125 88.89208984375 C 307.68096923828125 83.2086181640625 187.17343139648438 88.89210510253906 107.28968811035156 88.89210510253906 C 88.29312133789062 88.89210510253906 80.9753646850586 88.9740982055664 80.10201263427734 77.39600372314453"
              />
            </g>
          </SVG>
        </AbsoluteCase>
        <AbsoluteCase width={'11rem'} height={'3rem'}  marginTop={'2.5rem'}>
          <SVG
            viewBox={'170 -44 100 130'}
            width="100%"
            height="100%"
            version={'1.1'}
            xmlns={'http://www.w3.org/2000/svg'}
          >
            <PoseGroup
              pose={this.state.active ? 'go' : 'empty'}>
              <PathTextSecondary
                i={1}
                d="M 0 68.201 L 0 1.001 L 17.6 1.001 Q 25.6 1.001 29.7 5.101 A 13.699 13.699 0 0 1 33.476 12.441 A 19.73 19.73 0 0 1 33.8 16.101 L 33.8 52.901 A 20.338 20.338 0 0 1 33.295 57.569 A 13.614 13.614 0 0 1 29.8 64.101 A 12.571 12.571 0 0 1 24.612 67.239 Q 22.598 67.898 20.144 68.105 A 27.858 27.858 0 0 1 17.8 68.201 L 0 68.201 Z M 26.7 52.701 L 26.7 16.201 A 12.248 12.248 0 0 0 26.212 12.584 Q 24.548 7.201 17.2 7.201 L 7.1 7.201 L 7.1 61.901 L 17.2 61.901 Q 26.7 61.901 26.7 52.701 Z"
                key="10"
              />
              <PathTextSecondary
                i={2}
                d="M 46.5 68.201 L 46.5 1.001 L 53.6 1.001 L 53.6 68.201 L 46.5 68.201 Z"
                key="11"
              />
              <PathTextSecondary
                i={3}
                d="M 102.6 68.201 L 95.5 68.201 L 91.4 49.401 L 73.4 49.401 L 69.3 68.201 L 62.2 68.201 L 77.4 1.001 L 87.4 1.001 L 102.6 68.201 Z M 82.3 7.901 L 74.7 43.201 L 90 43.201 L 82.3 7.901 Z"
                key="12"
              />
              <PathTextSecondary
                i={4}
                d="M 110.2 54.501 L 110.2 15.301 Q 110.2 8.401 114.75 4.451 A 13.977 13.977 0 0 1 119.264 1.893 Q 121.333 1.153 123.895 0.807 A 36.663 36.663 0 0 1 128.8 0.501 A 43.064 43.064 0 0 1 138.555 1.58 A 39.118 39.118 0 0 1 140.9 2.201 L 140.9 9.001 Q 135 7.101 128.8 7.101 Q 123.492 7.101 120.786 8.676 A 6.577 6.577 0 0 0 119.95 9.251 A 6.882 6.882 0 0 0 117.599 13.054 Q 117.3 14.256 117.3 15.701 L 117.3 54.201 A 9.705 9.705 0 0 0 117.863 57.649 Q 119.625 62.301 126.9 62.301 Q 133.2 62.301 135.9 60.201 L 135.9 35.201 L 143 35.201 L 143 64.701 Q 135.9 68.701 127.5 68.701 A 30.781 30.781 0 0 1 121.078 68.092 Q 111.644 66.07 110.392 57.333 A 19.969 19.969 0 0 1 110.2 54.501 Z"
                key="1233"
              />
              <PathTextSecondary
                i={5}
                d="M 185.8 56.801 L 185.1 34.701 L 185.1 1.001 L 192.2 1.001 L 192.2 68.201 L 184 68.201 L 170.2 33.401 Q 169.445 31.419 163.079 14.562 A 115731.238 115731.238 0 0 1 162.3 12.501 L 162.9 34.501 L 162.9 68.201 L 156 68.201 L 156 1.001 L 164.2 1.001 A 8861.514 8861.514 0 0 1 171.303 19.103 Q 181.48 45.13 185.8 56.801 Z"
                key="114"
              />
              <PathTextSecondary
                i={6}
                d="M 205 53.401 L 205 15.901 A 19.127 19.127 0 0 1 205.656 10.766 A 14.512 14.512 0 0 1 209.3 4.401 A 13.684 13.684 0 0 1 215.164 0.92 Q 217.345 0.245 219.982 0.065 A 29.024 29.024 0 0 1 221.95 0.001 A 25.341 25.341 0 0 1 226.773 0.43 Q 229.584 0.976 231.758 2.214 A 12.834 12.834 0 0 1 234.6 4.401 A 14.874 14.874 0 0 1 238.679 12.822 A 20.724 20.724 0 0 1 238.9 15.901 L 238.9 53.401 A 19.043 19.043 0 0 1 238.244 58.528 A 14.3 14.3 0 0 1 234.6 64.851 A 13.649 13.649 0 0 1 228.887 68.241 Q 225.846 69.201 221.9 69.201 A 25.809 25.809 0 0 1 217.056 68.776 Q 214.347 68.258 212.241 67.106 A 12.607 12.607 0 0 1 209.25 64.851 Q 205 60.501 205 53.401 Z M 231.8 53.201 L 231.8 16.001 A 13.146 13.146 0 0 0 231.294 12.184 Q 230.02 7.988 225.534 6.89 A 15.11 15.11 0 0 0 221.95 6.501 A 14.756 14.756 0 0 0 218.112 6.955 Q 213.11 8.306 212.27 13.671 A 15.075 15.075 0 0 0 212.1 16.001 L 212.1 53.201 Q 212.1 62.801 221.95 62.801 Q 229.726 62.801 231.363 56.818 A 13.722 13.722 0 0 0 231.8 53.201 Z"
                key="13"
              />
              <PathTextSecondary
                i={8}
                d="M 247.6 20.501 L 247.6 14.601 A 15.895 15.895 0 0 1 248.206 10.095 A 11.966 11.966 0 0 1 252 4.251 Q 255.713 1.087 261.952 0.592 A 30.371 30.371 0 0 1 264.35 0.501 A 62.343 62.343 0 0 1 269.968 0.74 Q 274.363 1.138 277.8 2.201 L 277.8 9.001 A 28.752 28.752 0 0 0 272.81 7.702 Q 269.319 7.101 265.1 7.101 Q 255.346 7.101 254.829 14.096 A 10.922 10.922 0 0 0 254.8 14.901 L 254.8 19.701 A 14.686 14.686 0 0 0 254.945 21.836 Q 255.243 23.857 256.15 25.201 Q 257.124 26.644 259.062 27.983 A 20.934 20.934 0 0 0 260.7 29.001 L 272.3 35.501 Q 280.4 39.901 280.4 48.801 L 280.4 54.201 A 16.222 16.222 0 0 1 279.793 58.76 A 12.074 12.074 0 0 1 275.7 64.901 Q 272.018 67.877 265.79 68.522 A 35.381 35.381 0 0 1 262.15 68.701 A 64.226 64.226 0 0 1 256.975 68.505 Q 254.408 68.297 252.245 67.867 A 25.32 25.32 0 0 1 248.1 66.701 L 248.1 59.901 A 34.302 34.302 0 0 0 255.657 61.736 A 45.048 45.048 0 0 0 261.5 62.101 A 24.845 24.845 0 0 0 265.659 61.784 Q 273.04 60.524 273.291 54.249 A 11.21 11.21 0 0 0 273.3 53.801 L 273.3 49.601 Q 273.3 47.34 272.737 45.736 A 6.564 6.564 0 0 0 272.1 44.401 Q 270.9 42.501 267.8 40.801 L 256.3 34.501 Q 247.827 29.923 247.606 20.983 A 19.505 19.505 0 0 1 247.6 20.501 Z"
                key="14"
              />
              <PathTextSecondary
                i={9}
                d="M 284.5 1.001 L 317.7 1.001 L 317.7 7.301 L 304.7 7.301 L 304.7 68.201 L 297.5 68.201 L 297.5 7.301 L 284.5 7.301 L 284.5 1.001 Z"
                key="15"
              />
              <PathTextSecondary
                i={10}
                d="M 325.5 68.201 L 325.5 1.001 L 332.6 1.001 L 332.6 68.201 L 325.5 68.201 Z"
                key="16"
              />
              <PathTextSecondary
                i={11}
                d="M 345.4 54.501 L 345.4 14.701 A 15.757 15.757 0 0 1 346.578 8.364 Q 350.005 0.501 363.4 0.501 Q 369.2 0.501 375 2.101 L 375 8.701 Q 369.1 7.001 363.2 7.001 Q 358.363 7.001 355.878 8.378 A 5.923 5.923 0 0 0 354.9 9.051 Q 352.5 11.101 352.5 15.101 L 352.5 54.201 A 9.895 9.895 0 0 0 352.776 56.612 A 6.565 6.565 0 0 0 354.95 60.151 Q 357.064 61.919 361.933 62.162 A 32.537 32.537 0 0 0 363.55 62.201 A 42.333 42.333 0 0 0 373.115 61.144 A 38.584 38.584 0 0 0 375.2 60.601 L 375.2 67.101 A 31.597 31.597 0 0 1 370.117 68.249 Q 367.595 68.616 364.737 68.685 A 55.555 55.555 0 0 1 363.4 68.701 A 33.48 33.48 0 0 1 356.83 68.115 Q 346.549 66.047 345.515 56.684 A 19.91 19.91 0 0 1 345.4 54.501 Z"
                key="17"
              />
            </PoseGroup>
          </SVG>
        </AbsoluteCase>
      </Container>
    );
  }
}

export default LogoAnimation;
